.menu:hover {
    background-color: #00ff00;
    background-image: linear-gradient(315deg, #00ff00 0%, #00ee10 74%);
    color: white;
    cursor: pointer;
}

.menu {
    float: right;
    color: black;
    height: 100%;
    padding: 24px;
    font-size: 17px;
    font-weight: bold;
}

a {
    text-decoration: none;
    color: inherit
}

.before {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.95);
    box-shadow: 0.3px 0.3px 2px gray;
    z-index: 99;
    width: 100%;
    font-size: 16.5px;
    color: goldenrod;
    transition: all 0.4s ease-in-out;
}

.after {
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    /* box-shadow: 1px 1px 2px; */
    z-index: 99;
    width: 100%;
    font-size: 18px;
    color: goldenrod;
    transition: all 0.4s ease-in-out;
}

.nav-wrapper ul {
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
}

.nav-wrapper ul li a:hover {
    background-color: goldenrod;
    color: white;
    border-bottom-color: grey;
}

.before.nav-wrapper ul li a:hover {
    background-color: goldenrod;
    color: white;
    border-bottom-color: grey;
}

.nav-wrapper ul li a:hover {
    color: white;
}

.nav-wrapper ul li a {
    color: white;
    font-weight: bolder;
}
.before.nav-wrapper ul li a {
    transition: all 0.4s ease-in-out;
    color: goldenrod;
    font-weight: bolder;
}

.nav-wrapper ul li {
    display: inline-block;
    border-radius: 5px;
}

.nav-wrapper ul li a {
    display: inline-block;
    padding: 12px;
    margin: 5px;
    right: 0px;
    border-radius: 5px;
}

.brand-logo {
    margin-right: 50%;
}

.brand-logo1 {
    margin-right: 40%;
}

.logo_img1 {
    width: 6%;
    margin-bottom: -15px;
}

.logo_img {
    width: 5.3%;
    margin-bottom: -14px;
}

.logo_img_small {
    width: 58%;
    margin-bottom: -15px;
}

.particle {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/sam/paris.webp);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.scientific {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/scientific.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.web {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/back1.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.port {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/back3.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.contact {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/contact2.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.cv {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/back2.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.service1 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    background-image: url(img/code/scientific.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.services1 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.zoomer{
    transition: all 0.4s ease-in-out;

    margin-top: -5px;
    transition: all 0.4s ease-in-out;

}

.zoomer:hover{
    transition: all 0.4s ease-in-out;

    margin-top: -20px;
    transition: all 0.4s ease-in-out;

}