@import url(//fonts.googleapis.com/css?family=Lato:300:400);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap);
body {
    margin: 0;
    font-family:  sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: hsl(227, 95%, 16%);
    font-size: 15px;
}

code {
    font-family:  monospace;
}
body {
    margin: 0;
}

h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 48px;
}

p {
    font-family: sans-serif;
    letter-spacing: 1px;
    font-size: 14px;
    color: #333333;
}

.header {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: white;
}

.logo {
    width: 50px;
    fill: white;
    padding-right: 15px;
    display: inline-block;
    vertical-align: middle;
}

.inner-header {
    height: 65vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
}


/* Animation */

.parallax>use {
    -webkit-animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
            animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 13s;
            animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
}

@-webkit-keyframes move-forever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

@keyframes move-forever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}


/*Shrinking for mobile*/
.overlap{
    background-color: white;
}

@media (max-width: 768px) {
  .styler{
    margin-left: 7%;
  }
    .topper{
        margin-top:-130px
    }
    .waves {
        height: 40px;
        min-height: 40px;
    }
    .content {
        height: 30vh;
    }
    h1 {
        font-size: 24px;
    }
}



@media (min-width: 768px) {
  .styler{
    margin-left: -40px;
  }
    .topper{
        margin-top:-130px
    }
    .waves {
        height: 40px;
        min-height: 40px;
    }
    .content {
        height: 30vh;
    }
  .overlap:hover{
    margin-left: 50px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu:hover {
    background-color: #00ff00;
    background-image: linear-gradient(315deg, #00ff00 0%, #00ee10 74%);
    color: white;
    cursor: pointer;
}

.menu {
    float: right;
    color: black;
    height: 100%;
    padding: 24px;
    font-size: 17px;
    font-weight: bold;
}

a {
    text-decoration: none;
    color: inherit
}

.before {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.95);
    box-shadow: 0.3px 0.3px 2px gray;
    z-index: 99;
    width: 100%;
    font-size: 16.5px;
    color: goldenrod;
    transition: all 0.4s ease-in-out;
}

.after {
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    /* box-shadow: 1px 1px 2px; */
    z-index: 99;
    width: 100%;
    font-size: 18px;
    color: goldenrod;
    transition: all 0.4s ease-in-out;
}

.nav-wrapper ul {
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
}

.nav-wrapper ul li a:hover {
    background-color: goldenrod;
    color: white;
    border-bottom-color: grey;
}

.before.nav-wrapper ul li a:hover {
    background-color: goldenrod;
    color: white;
    border-bottom-color: grey;
}

.nav-wrapper ul li a:hover {
    color: white;
}

.nav-wrapper ul li a {
    color: white;
    font-weight: bolder;
}
.before.nav-wrapper ul li a {
    transition: all 0.4s ease-in-out;
    color: goldenrod;
    font-weight: bolder;
}

.nav-wrapper ul li {
    display: inline-block;
    border-radius: 5px;
}

.nav-wrapper ul li a {
    display: inline-block;
    padding: 12px;
    margin: 5px;
    right: 0px;
    border-radius: 5px;
}

.brand-logo {
    margin-right: 50%;
}

.brand-logo1 {
    margin-right: 40%;
}

.logo_img1 {
    width: 6%;
    margin-bottom: -15px;
}

.logo_img {
    width: 5.3%;
    margin-bottom: -14px;
}

.logo_img_small {
    width: 58%;
    margin-bottom: -15px;
}

.particle {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/paris.b487f5f6.webp);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.scientific {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/scientific.d1397746.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.web {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/back1.0aa97fcb.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.port {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/back3.79dcf92f.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.contact {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/contact2.1d9e45cf.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.cv {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/back2.c39ebe34.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.service1 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    background-image: url(/static/media/scientific.d1397746.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.services1 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.zoomer{
    transition: all 0.4s ease-in-out;

    margin-top: -5px;
    transition: all 0.4s ease-in-out;

}

.zoomer:hover{
    transition: all 0.4s ease-in-out;

    margin-top: -20px;
    transition: all 0.4s ease-in-out;

}
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@media (min-width: 600px) {
    
    .overlap{
        position:relative;
        margin-left: -100px;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

        .container .box {
            position: relative;
            width: 30rem;
            height: 30rem;
            margin: 1rem;
        }

            .container .box:hover .imgBox {
                -webkit-transform: translate(-2.5rem, -2.5rem);
                        transform: translate(-2.5rem, -2.5rem);
            }

            .container .box:hover .content {
                -webkit-transform: translate(2.5rem, 2.5rem);
                        transform: translate(2.5rem, 2.5rem);
            }

    .imgBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all 0.5s ease-in-out;
    }

        .imgBox img {
            width: 30rem;
            height: 30rem;
            -o-object-fit: cover;
               object-fit: cover;
            resize: both;
        }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;
        z-index: 1;
        align-items: flex-end;
        text-align: center;
        transition: 0.5s ease-in-out;
    }

        .content h2 {
            display: block;
            font-size: 2rem;
            color: #111;
            font-weight: 500;
            line-height: 2rem;
            letter-spacing: 1px;
        }

        .content span {
            color: #555;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 2px;
        }
}

@media (max-width: 600px) {


    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

        .container .box {
            position: relative;
            width: 19rem;
            height: 19rem;
        }

            .container .box:hover .imgBox {
                -webkit-transform: translate(-3.5rem, -3.5rem);
                        transform: translate(-3.5rem, -3.5rem);
            }

            .container .box:hover .content {
                -webkit-transform: translate(3.5rem, 3.5rem);
                        transform: translate(3.5rem, 3.5rem);
            }

    .imgBox {
        position: absolute;

        width: 19rem;
        height: 19rem;
        z-index: 2;
        transition: all 0.5s ease-in-out;
    }

        .imgBox img {
            width: 19rem;
            height: 19rem;
            -o-object-fit: cover;
               object-fit: cover;
            resize: both;
        }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;
        z-index: 1;
        align-items: flex-end;
        text-align: center;
        transition: 0.5s ease-in-out;
    }

        .content h2 {
            display: block;
            font-size: 2rem;
            color: #111;
            font-weight: 500;
            line-height: 2rem;
            letter-spacing: 1px;
        }

        .content span {
            color: #555;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 2px;
        }


    .container .box:hover .content {
        -webkit-transform: translate(0, 3rem);
                transform: translate(0, 3rem);
        margin-top:0rem;
    }

    .container .box:hover .imgBox {
        -webkit-transform: translate(0, -3rem);
                transform: translate(0, -3rem);
        margin-bottom: 50rem;
        margin-top: 1rem;
    }
}
/*# sourceMappingURL=main.css.map */

