@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@media (min-width: 600px) {
    
    .overlap{
        position:relative;
        margin-left: -100px;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

        .container .box {
            position: relative;
            width: 30rem;
            height: 30rem;
            margin: 1rem;
        }

            .container .box:hover .imgBox {
                transform: translate(-2.5rem, -2.5rem);
            }

            .container .box:hover .content {
                transform: translate(2.5rem, 2.5rem);
            }

    .imgBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all 0.5s ease-in-out;
    }

        .imgBox img {
            width: 30rem;
            height: 30rem;
            object-fit: cover;
            resize: both;
        }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;
        z-index: 1;
        align-items: flex-end;
        text-align: center;
        transition: 0.5s ease-in-out;
    }

        .content h2 {
            display: block;
            font-size: 2rem;
            color: #111;
            font-weight: 500;
            line-height: 2rem;
            letter-spacing: 1px;
        }

        .content span {
            color: #555;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 2px;
        }
}

@media (max-width: 600px) {


    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

        .container .box {
            position: relative;
            width: 19rem;
            height: 19rem;
        }

            .container .box:hover .imgBox {
                transform: translate(-3.5rem, -3.5rem);
            }

            .container .box:hover .content {
                transform: translate(3.5rem, 3.5rem);
            }

    .imgBox {
        position: absolute;

        width: 19rem;
        height: 19rem;
        z-index: 2;
        transition: all 0.5s ease-in-out;
    }

        .imgBox img {
            width: 19rem;
            height: 19rem;
            object-fit: cover;
            resize: both;
        }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;
        z-index: 1;
        align-items: flex-end;
        text-align: center;
        transition: 0.5s ease-in-out;
    }

        .content h2 {
            display: block;
            font-size: 2rem;
            color: #111;
            font-weight: 500;
            line-height: 2rem;
            letter-spacing: 1px;
        }

        .content span {
            color: #555;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 2px;
        }


    .container .box:hover .content {
        transform: translate(0, 3rem);
        margin-top:0rem;
    }

    .container .box:hover .imgBox {
        transform: translate(0, -3rem);
        margin-bottom: 50rem;
        margin-top: 1rem;
    }
}
/*# sourceMappingURL=main.css.map */
